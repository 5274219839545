import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import './globalStyle.scss'
import App from './router/index';
import * as serviceWorker from './serviceWorker';

console.log('文件上传12')
ReactDOM.render(
  // <React.StrictMode>
    <App />,
  // </React.StrictMode>,
  document.getElementById('root')
);

// 模块热替换的 API
if (module.hot) {
    module.hot.accept();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
