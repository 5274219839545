import React, {Component} from 'react';
import {Link, NavLink} from "react-router-dom";
import {BackTop, Tooltip} from 'antd';

import './header.scss'

const headerLink = [
    {
        name: '首页',
        path: '/',
        isClick: true,
        childArray: ['/']
    },
    {
        name: '解决方案',
        isClick: false,
        childArray: [{name: '企业', path: '/enterprise'}, {name: '医疗行业', path: '/medical'}]
    },
    {
        name: '合作案例',
        path: '/cooperation',
        isClick: true,
        childArray: ['/cooperation', '/hospital']
    },
    {
        name: '数据入口',
        path: '/data',
        isClick: true,
        childArray: ['/data']
    },
    {
        name: '关于我们',
        path: '/contactus',
        isClick: true,
        childArray: ['/contactus']
    },
]
const Style = {
    fontWeight: 'bold',
    borderBottom: "3px solid #6ca4ef",

};

class Header extends Component {

    render() {
        return (
            <div className='header'>
                <div className='header-row'>

                    {headerLink.map((item) => {
                        if (item.isClick) {
                            return (
                                <NavLink
                                    key={item.path}
                                    to={item.path}
                                    exact
                                    className="header-link"
                                    activeStyle={Style}
                                    isActive={(match, {pathname}) => {
                                        return item.childArray.includes(pathname);
                                    }}
                                >
                                    {item.name}
                                </NavLink>
                            )
                        } else {
                            const childArray = <div>{item.childArray.map((data) => {
                                return <div key={data.name}>
                                    <Link to={data.path}> <span className='font-18-fff'>{data.name}</span></Link>
                                </div>
                            })}</div>
                            return (
                                <Tooltip
                                    title={childArray}
                                    placement="bottom"
                                    className='header-link font-18-fff' key={item.name}>
                                    {item.name}
                                </Tooltip>
                            )
                            // <span className='header-link font-18-fff' key={item.name}>{item.name}</span>
                            // return
                        }
                    })}
                </div>

                <BackTop/>
            </div>
        );
    }
}

export default Header;
