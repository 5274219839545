import React, { Component, lazy, Suspense } from 'react'
import { Route, Router, Switch, useHistory } from 'react-router-dom'

import { createBrowserHistory } from 'history'
import Footers from '../pages/FooterComponent/Footer'
import Headers from '../pages/headerComponen/header'
import { Layout } from 'antd'
import './layout.scss'

const { Header, Content, Footer } = Layout
const history = createBrowserHistory({ basename: '/' })
const minHeight = { minHeight: '100vh' }

const Home = lazy(() => import('../pages/home/home'))
const Data = lazy(() => import('../pages/data/data'))
const Contactus = lazy(() => import('../pages/contactus/contactus'))
const Enterprise = lazy(() => import('../pages/enterprise/enterprise'))
const Medical = lazy(() => import('../pages/medical/medical'))
const Cooperation = lazy(() => import('../pages/cooperation/cooperation'))
const Hospital = lazy(() => import('../pages/hospital/hospital'))
const WX = lazy(() => import('../wx/wx'))

const Index = () => {
    return (
        <Router history={history}>
            <Switch>
                <Suspense fallback={<div>Loading...</div>}>
                    {['/wx/','/wx'].includes(window.location.pathname)  ? (
                        <Route exact path="/wx" component={WX} />
                    ) : (
                        <Layout className="Layout" style={minHeight}>
                            <Header>
                                <Switch>
                                    <Headers />
                                </Switch>
                            </Header>
                            <Content>
                                <Route exact path="/" component={Home} />
                                <Route exact path="/data" component={Data} />
                                <Route exact path="/contactus" component={Contactus} />
                                <Route exact path="/enterprise" component={Enterprise} />
                                <Route exact path="/medical" component={Medical} />
                                <Route exact path="/cooperation" component={Cooperation} />
                                <Route exact path="/hospital" component={Hospital} />
                            </Content>
                            <Footer>
                                <Footers />
                            </Footer>
                        </Layout>
                    )}
                </Suspense>
            </Switch>
        </Router>
    )
}

export default Index
