import React, {Component} from 'react';
import './footer.scss'
import {Divider} from "antd";


class Footer extends Component {
    render() {
        return (
            <div className='footer'>
                <div className='footer-left'>
                    <div className='footer-left-p'>
                        <span className='font-24-fff'>联系我们</span>
                        <Divider className='footer-left-p-cum' type='vertical'/>
                        <span className='font-24-fff'>Contact Us</span>
                    </div>
                    <p className='footer-left-p2'>
                        <span className='font-16-fff'>易得医数科技有限责任公司</span>
                        <br/>
                        <span className='font-16-fff'>电话: 010-62575208</span>
                        <br/>
                        <span className='font-16-fff'>邮箱: support@edocdata.com</span>
                        <br/>
                        <span className='font-16-fff'>地址: 北京市海淀区善缘街1号3段</span>
                        <br/>
                        <span className='font-16-fff'>© 北京易得医数科技有限责任公司版权所有 </span><span
                        onClick={this.openWindows}
                        className='font-16-fff click'>京ICP备17044059</span>
                    </p>
                </div>
                <div className='footer-right'>
                    <img className='footer-right-img' src={require('../../img/weixing.png')} alt="微信公众号搜索 易得医数"/>
                    关注我们，使您的数据管理更轻松

                </div>
            </div>
        );
    }
    openWindows = () =>{
        window.open('http://beian.miit.gov.cn/')
    }
}

export default Footer;
